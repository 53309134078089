import type { ElectronClient } from '@openphone/desktop-client'
import { ForbiddenError } from '@openphone/internal-api-client'
import type { Env } from '@openphone/web-config'
import * as Sentry from '@sentry/react'
import Debug from 'debug'
import { action, flowResult, makeAutoObservable, reaction, runInAction, when } from 'mobx'
import type { Location, createBrowserRouter } from 'react-router-dom'
import { NavigationType } from 'react-router-dom'
import type { Observable } from 'rxjs'
import { asyncScheduler, of } from 'rxjs'
import { filter, mergeMap, throttleTime } from 'rxjs/operators'

import { MAX_VISIBLE_HISTORY_ITEMS } from '@src/app/components/RecentHistory'
import type { MediaViewerState } from '@src/component/MediaViewer'
import config from '@src/config'
import { fromQueryString } from '@src/lib'
import type LoadableStatus from '@src/lib/LoadableStatus'
import delayObservable from '@src/lib/delayObservable/delayObservable'
import { isPwa } from '@src/lib/device'
import exponentialDelay from '@src/lib/exponentialDelay'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import type { IdentifyData } from '@src/service/analytics'
import type { IFlagsService } from '@src/service/feature-flags'
import type { MessageMediaModel } from '@src/service/model'
import type StorageService from '@src/service/storage/StorageService'
import makePersistable from '@src/service/storage/makePersistable'
import type { StorageThemeKey } from '@src/theme'
import type { TypographyThemeKey } from '@ui/theme'

import type { EmojiPickerProps } from './AppEmojiPicker'
import NotificationController from './NotificationController'
import CommandUiStore from './command/CommandUiStore'
import { ErrorHelper, SentryManager } from './error'
import { LoginUiStore } from './login/store'
import PromptUiStore from './prompt'
import type {
  LocationSearch,
  LocationSearchParam,
  SafeAbsoluteNavigateFunction,
} from './router'
import {
  createSafeAbsoluteNavigate,
  stripSearchParam,
  stripUnknownParams,
} from './router'
import Sift from './sift'
import { SoundUiStore } from './sound'
import { ToastUiStore } from './toast'
import AppUpdateController from './update/AppUpdateController'
import { URLController } from './url'

type Router = ReturnType<typeof createBrowserRouter>

type EmojiPickerState = Omit<EmojiPickerProps, 'targetRef'> &
  (
    | {
        open: true
        targetRef: EmojiPickerProps['targetRef']
      }
    | {
        open: false
        targetRef: null
      }
  )

const HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL = 'HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL'

async function loadUiStores() {
  const [
    AlertsUiStore,
    AnalyticsUiStore,
    BillingUiStore,
    CallActivityUiStore,
    CnamUiStore,
    ContactsUiStore,
    ConversationUiStore,
    HelpAndSupportUiStore,
    InboxesUiStore,
    PortRequestUiStore,
    ScheduledMessagesUiStore,
    SearchUiStore,
    SideMenuUiStore,
    PhoneNumberUiStore,
    TollFreeRegistrationUiStore,
    TrustRegistrationUiStore,
    WorkspaceUiStore,
    VoiceUiStore,
    CallsViewUiStore,
    AiCallAssistantUiStore,
  ] = await Promise.all([
    import('./alerts/AlertsUiStore').then((m) => m.AlertsUiStore),
    import('./analytics/AnalyticsUiStore').then((m) => m.default),
    import('./billing/BillingUiStore').then((m) => m.default),
    import('./inbox/conversation/activity/CallActivityUiStore').then((m) => m.default),
    import('./cnam').then((m) => m.CnamUiStore),
    import('./contacts/ContactsUiStore').then((m) => m.default),
    import('./inbox/conversation/ConversationUiStore').then((m) => m.default),
    import('@src/app/help-and-support/HelpAndSupportUiStore').then((m) => m.default),
    import('./inbox/InboxesUiStore').then((m) => m.default),
    import('./porting').then((m) => m.PortRequestUiStore),
    import('./scheduled-message').then((m) => m.ScheduledMessagesUiStore),
    import('./search/store').then((m) => m.default),
    import('./side-menu/SideMenuUiStore').then((m) => m.default),
    import('./settings/phone-number/PhoneNumberUiStore').then((m) => m.default),
    import('./toll-free-registration').then((m) => m.TollFreeRegistrationUiStore),
    import('./trust-registration').then((m) => m.TrustRegistrationUiStore),
    import('./workspace').then((m) => m.WorkspaceUiStore),
    import('./voice').then((m) => m.VoiceUiStore),
    import('./inbox/calls/store/CallsViewUiStore').then((m) => m.default),
    import('./settings/phone-number/AiCallAssistant/AiCallAssistantUiStore').then(
      (m) => m.default,
    ),
  ])

  return {
    AlertsUiStore,
    AnalyticsUiStore,
    BillingUiStore,
    CallActivityUiStore,
    CnamUiStore,
    ContactsUiStore,
    ConversationUiStore,
    HelpAndSupportUiStore,
    InboxesUiStore,
    PortRequestUiStore,
    ScheduledMessagesUiStore,
    SearchUiStore,
    SideMenuUiStore,
    PhoneNumberUiStore,
    TollFreeRegistrationUiStore,
    TrustRegistrationUiStore,
    WorkspaceUiStore,
    VoiceUiStore,
    CallsViewUiStore,
    AiCallAssistantUiStore,
  } as const
}

type LoadedTypes = Awaited<ReturnType<typeof loadUiStores>>

type UiStoreTypes = {
  [K in keyof LoadedTypes]: InstanceType<LoadedTypes[K]>
}

export default class AppStore {
  readonly command: CommandUiStore
  readonly history: HistoryManager

  readonly debug = {
    enable(value?: string) {
      Debug.enable(typeof value === 'string' ? value : '*')
    },
    disable() {
      Debug.disable()
    },
  }

  private _alerts: UiStoreTypes['AlertsUiStore'] | null = null
  private _billing: UiStoreTypes['BillingUiStore'] | null = null
  private _callActivity: UiStoreTypes['CallActivityUiStore'] | null = null
  private _contacts: UiStoreTypes['ContactsUiStore'] | null = null
  private _conversation: UiStoreTypes['ConversationUiStore'] | null = null
  private _analytics: UiStoreTypes['AnalyticsUiStore'] | null = null
  private _inboxes: UiStoreTypes['InboxesUiStore'] | null = null
  private _search: UiStoreTypes['SearchUiStore'] | null = null
  private _scheduledMessages: UiStoreTypes['ScheduledMessagesUiStore'] | null = null
  private _sideMenu: UiStoreTypes['SideMenuUiStore'] | null = null
  private _phoneNumber: UiStoreTypes['PhoneNumberUiStore'] | null = null
  private _trustRegistration: UiStoreTypes['TrustRegistrationUiStore'] | null = null
  private _tollFreeRegistration: UiStoreTypes['TollFreeRegistrationUiStore'] | null = null
  private _workspace: UiStoreTypes['WorkspaceUiStore'] | null = null
  private _cnam: UiStoreTypes['CnamUiStore'] | null = null
  private _portRequest: UiStoreTypes['PortRequestUiStore'] | null = null
  private _helpAndSupport: UiStoreTypes['HelpAndSupportUiStore'] | null = null
  private _voice: UiStoreTypes['VoiceUiStore'] | null = null
  private _callsViewCache: UiStoreTypes['CallsViewUiStore'] | null = null
  private _aiCallAssistant: UiStoreTypes['AiCallAssistantUiStore'] | null = null

  private scrollTimeout: number | null = null

  login: LoginUiStore
  toast: ToastUiStore
  error: ErrorHelper
  sound: SoundUiStore
  sift?: Sift
  prompt: PromptUiStore
  config = config
  confetti = false
  darkMode = true
  emojiPicker: EmojiPickerState = { open: false, targetRef: null }
  mediaViewer: MediaViewerState | null = null
  storesLoaded = false
  url: URLController
  readonly update: AppUpdateController
  notification: NotificationController
  themeKey: StorageThemeKey = 'system'
  typographyThemeKey: TypographyThemeKey = 'default'
  hasNetworkConnection = navigator.onLine
  isScrolling = false
  loadingPercentage = 0
  loadingStatus: LoadableStatus = 'idle'
  initializePromise: Promise<void>

  constructor(
    readonly electron: ElectronClient | null,
    readonly router: Router,
    readonly service: Service,
  ) {
    this.history = new HistoryManager(
      router,
      service.storage,
      service.flags,
      this.isStandaloneApp,
    )
    this.url = new URLController(this)
    this.notification = new NotificationController(this)
    this.command = new CommandUiStore(this)
    this.toast = new ToastUiStore(this)
    this.error = new ErrorHelper(this)
    this.sift = new Sift(this)
    this.update = new AppUpdateController(this)
    this.login = new LoginUiStore(this)
    this.sound = new SoundUiStore()
    this.prompt = new PromptUiStore(this.sound)

    this.initializePromise = when(() => this.initialized && this.storesLoaded, {
      name: 'AppStore.initializePromise',
    })

    makeAutoObservable(this, {
      config: false,
      debug: false,
      electron: false,
      history: false,
      service: false,
      setThemeKey: action.bound,
      setTypographyThemeKey: action.bound,
    })

    makePersistable(this, 'AppStore', {
      themeKey: this.service.storage.sync(),
      typographyThemeKey: this.service.storage.sync(),
    })

    // old auth flow
    when(
      () => service.auth.hasSession,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        flowResult(this.initialize()).then(() =>
          this.service.transport.runPendingTransactions(),
        )
      },
    )

    // new auth flow using universal login
    when(
      () => service.authV2.isAuthenticated,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        flowResult(this.initialize()).then(() =>
          this.service.transport.runPendingTransactions(),
        )
      },
    )

    when(
      () => this.update.initialUpdateStatus === 'complete',
      () => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.electron?.window.onInitialized?.()
        } catch {
          // ignore errors
        }
      },
    )

    // TODO: UXP-4036 - Remove this since the reference to flags never change
    // and it can be done synchronously if needed and the rest of the logic can
    // move to domain stores
    reaction(
      () => [service.flags.flags, this.initialized],
      () => {
        if (this.initialized) {
          if (import.meta.env.PROD) {
            // assign a delay to avoid overloading the server
            const delay = exponentialDelay(60_000)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
            delay().then(() => this.loadFlagDependentEssentials())
          } else {
            this.loadFlagDependentEssentials()
          }
        }

        // Enable Sentry if the flag is enabled and we're not in dev builds
        const isSentryEnabled = service.flags.getFlag('sentry') && import.meta.env.PROD
        const sentryTracesSampleRate = service.flags.getFlag('sentryTracesSampleRate')
        const sentryBrowserProfilingSampleRate = service.flags.getFlag(
          'webSentryBrowserProfilingSampleRate',
        )

        SentryManager.setTracesSampleRate(sentryTracesSampleRate)
        SentryManager.setBrowserProfilingSampleRate(sentryBrowserProfilingSampleRate)

        if (isSentryEnabled) {
          SentryManager.enable()
        } else {
          SentryManager.disable()
        }
      },
      { name: 'AppStore.FlagsChanged' },
    )

    reaction(
      () =>
        this.initialized && service.organization.current?.id && service.transport.online,
      (shouldRun) => {
        if (shouldRun) {
          service.member.fetchPresence().catch(logError)
        }
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.initialized,
      (loaded) => {
        if (loaded) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.loadUiStores()
        } else {
          this.tearDownUiStores()
        }
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.unreadActivitiesCount,
      (count) => {
        this.electron?.app?.setBadgeCount?.(count).catch(() => null)
        navigator.setAppBadge?.(count).catch(() => null)
      },
      { fireImmediately: true },
    )

    this.service.voice.onRefreshRejected = () => {
      this.toast.showError('Could not refresh voice token.')
      Sentry.captureMessage(
        'Logging out the user due not being able to refresh a voice token',
        'debug',
      )

      if (this.service.authV2.isEnabled) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.service.universalLoginReset()
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.service.clearAllAndRestart()
      }
    }

    this.electron?.on('tray', (event) => {
      if (event.type === 'click') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.electron?.window.focus?.()
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.electron?.window.show?.()
      }
    })

    if (this.is('windows')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.electron?.app.createTray?.()
    }

    this.setAsDefaultTelProtocol()

    document.addEventListener('scroll', this.onDocumentScroll, true)

    window.addEventListener(
      'online',
      action(() => {
        this.hasNetworkConnection = true
      }),
    )

    window.addEventListener(
      'offline',
      action(() => {
        this.hasNetworkConnection = false
      }),
    )

    // We need to handle Dexie error using addEventListener since `on('error')` is deprecated https://dexie.org/docs/Dexie/Dexie.on.error
    window.addEventListener('unhandledrejection', (error) => {
      if (
        error &&
        typeof error === 'object' &&
        error.reason &&
        typeof error.reason === 'object' &&
        'name' in error.reason
      ) {
        const reason = error.reason as { name: string }
        if (reason.name === 'VersionError') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.signOut()
        }
      }
    })

    service.authV2.onRefreshTokenError = (_err) => {
      this.notification.show({
        id: 'universal-login-log-out',
        title: 'Logged out',
        body: 'You have been logged out. Please log in again to continue using the app.',
        sound: 'default',
      })
    }
  }

  get alerts(): UiStoreTypes['AlertsUiStore'] {
    if (this._alerts) {
      return this._alerts
    }
    throw new Error('alerts store is not initialized')
  }

  get billing(): UiStoreTypes['BillingUiStore'] {
    if (this._billing) {
      return this._billing
    }
    throw new Error('billing store is not initialized')
  }

  get callActivity(): UiStoreTypes['CallActivityUiStore'] {
    if (this._callActivity) {
      return this._callActivity
    }
    throw new Error('call activity store is not initialized')
  }

  get contacts(): UiStoreTypes['ContactsUiStore'] {
    if (this._contacts) {
      return this._contacts
    }
    throw new Error('contacts store is not initialized')
  }

  get conversation(): UiStoreTypes['ConversationUiStore'] {
    if (this._conversation) {
      return this._conversation
    }
    throw new Error('conversation store is not initialized')
  }

  get analytics(): UiStoreTypes['AnalyticsUiStore'] {
    if (this._analytics) {
      return this._analytics
    }
    throw new Error('analytics store is not initialized')
  }

  get inboxes(): UiStoreTypes['InboxesUiStore'] {
    if (this._inboxes) {
      return this._inboxes
    }
    throw new Error('inboxes store is not initialized')
  }

  get search(): UiStoreTypes['SearchUiStore'] {
    if (this._search) {
      return this._search
    }
    throw new Error('search store is not initialized')
  }

  get scheduledMessages(): UiStoreTypes['ScheduledMessagesUiStore'] {
    if (this._scheduledMessages) {
      return this._scheduledMessages
    }
    throw new Error('scheduled messages store is not initialized')
  }

  get sideMenu(): UiStoreTypes['SideMenuUiStore'] {
    if (this._sideMenu) {
      return this._sideMenu
    }
    throw new Error('side menu store is not initialized')
  }

  get phoneNumber(): UiStoreTypes['PhoneNumberUiStore'] {
    if (this._phoneNumber) {
      return this._phoneNumber
    }
    throw new Error('phone number store is not initialized')
  }

  get trustRegistration(): UiStoreTypes['TrustRegistrationUiStore'] {
    if (this._trustRegistration) {
      return this._trustRegistration
    }
    throw new Error('trust registration store is not initialized')
  }

  get tollFreeRegistration(): UiStoreTypes['TollFreeRegistrationUiStore'] {
    if (this._tollFreeRegistration) {
      return this._tollFreeRegistration
    }
    throw new Error('toll free registration store is not initialized')
  }

  get workspace(): UiStoreTypes['WorkspaceUiStore'] {
    if (this._workspace) {
      return this._workspace
    }
    throw new Error('workspace store is not initialized')
  }

  get cnam(): UiStoreTypes['CnamUiStore'] {
    if (this._cnam) {
      return this._cnam
    }
    throw new Error('cnam store is not initialized')
  }

  get portRequest(): UiStoreTypes['PortRequestUiStore'] {
    if (this._portRequest) {
      return this._portRequest
    }
    throw new Error('port request store is not initialized')
  }

  get helpAndSupport(): UiStoreTypes['HelpAndSupportUiStore'] | null {
    return this._helpAndSupport
  }

  get callsView(): UiStoreTypes['CallsViewUiStore'] {
    if (this._callsViewCache) {
      return this._callsViewCache
    }
    throw new Error('calls view store is not initialized')
  }

  get aiCallAssistant(): UiStoreTypes['AiCallAssistantUiStore'] {
    if (this._aiCallAssistant) {
      return this._aiCallAssistant
    }
    throw new Error('ai call assistant store is not initialized')
  }

  get isElectron() {
    return Boolean(this.electron)
  }

  get isStandaloneApp() {
    return this.isElectron || isPwa()
  }

  get isLoggedIn(): boolean {
    return (
      this.service.authV2.isAuthenticated || Boolean(this.service.auth.session?.idToken)
    )
  }

  get isFocused() {
    return document.hasFocus()
  }

  get needsOnboarding(): boolean | undefined {
    const user = this.service.user.current
    const phoneNumbers = this.service.user.phoneNumbers

    const currentOrgInvite = this.service.user.inviteForThisOrg
    const invitedWithNumber = currentOrgInvite ? currentOrgInvite.needsNewNumber : false

    if (!this.service.phoneNumber.loaded) {
      return undefined
    }

    const needsNumber = phoneNumbers.length === 0 || invitedWithNumber

    return (
      needsNumber ||
      (user && !user?.firstName && !user?.lastName) ||
      this.hasPendingInvites ||
      this.isSubscriptionReviewNull
    )
  }

  get isSubscriptionReviewNull(): boolean {
    return (
      this.service.billing.subscription?.reviewStatus === null &&
      this.service.phoneNumber.loaded &&
      this.service.user.phoneNumbers.length > 0
    )
  }

  get isAccountFlagged(): boolean {
    return this.service.billing.subscription?.needsReview ?? false
  }

  get hasPendingInvites(): boolean {
    return this.service.user.invites.length > 0
  }

  private get onSocketsDown(): Observable<void> {
    return this.service.transport.connectivity.downtime.pipe(
      // Filter shorter downtimes
      filter((downtime) => downtime > 5_000),
      // Map to void
      mergeMap(() => of(undefined as void)),
      // Throttle so we don't spam the backend
      throttleTime(60_000, asyncScheduler, { leading: true, trailing: true }),
    )
  }

  onDataNeedsRefresh(
    ...args: [
      Parameters<typeof delayObservable>[1],
      Parameters<typeof delayObservable>[2]?,
    ]
  ) {
    return delayObservable(this.onSocketsDown, ...args)
  }

  get unreadActivitiesCount() {
    if (!this.storesLoaded) {
      return 0
    }

    let count = Object.entries(this.service.conversation.unreadCounts).reduce(
      (acc, [id, count]) => {
        if (this.inboxes.all.get(id)?.muted) {
          return acc
        }
        return acc + count
      },
      0,
    )
    count += this.alerts.unread
    count +=
      this.inboxes.dm?.conversations.list.reduce(
        (total, conversation) => total + conversation.unreadCount,
        0,
      ) ?? 0
    return count
  }

  get initialized(): boolean {
    return this.loadingStatus === 'success' && this.update.initialized
  }

  get initializationStatus(): LoadableStatus | 'updating' {
    return this.update.initialUpdateStatus === 'updating'
      ? 'updating'
      : this.loadingStatus
  }

  private get essentials(): (() => Promise<unknown>)[] {
    return [
      () => this.service.user.fetch(),
      () => this.service.user.fetchInvites(),
      () => this.service.phoneNumber.fetch(),
      () => this.service.member.fetch(),
      () => this.service.organization.fetch(),
      () => this.service.organization.fetchRoles(),
      () => this.service.organization.phoneNumber.fetch(),
      () => this.service.capabilities.fetch(),
      () => this.service.billing.fetchSubscription(),
    ]
  }

  private get flagDependentEssentials(): (() => Promise<unknown>)[] {
    // Only admins and owners can fetch CNAM data.
    const shouldFetchCnam =
      this.service.capabilities.features.callerIdEnabled &&
      this.service.user.current?.asMember?.isAdmin
    const snippetsEnabled = this.service.capabilities.features.snippetsEnabled

    const shouldFetchTrustRegistration = this.service.flags.getFlag('a2pBlockMessaging')

    const tollFreeMessageBlockingEnabled = this.service.flags.getFlag(
      'tollFreeMessageBlocking',
    )

    const trustCenterTollFreeEnabled = this.service.flags.getFlag('trustCenterTollFree')

    return [
      shouldFetchCnam ? () => this.service.cnam.fetch() : null,
      snippetsEnabled ? () => this.service.snippet.fetch() : null,
      shouldFetchTrustRegistration
        ? () => this.service.trustRegistrationV2.fetch()
        : null,
      tollFreeMessageBlockingEnabled || trustCenterTollFreeEnabled
        ? () => this.service.tollFreeRegistration.fetchLite()
        : null,
    ].filter(isNonNull)
  }

  private get loadingPercentageStep(): number {
    return Math.round(100 / this.essentials.length)
  }

  private onDocumentScroll = action(() => {
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout)
    }
    this.isScrolling = true
    this.scrollTimeout = window.setTimeout(
      action(() => {
        this.isScrolling = false
      }),
      100,
    )
  })

  is(platform: 'web' | 'mac' | 'windows') {
    const current = ((): 'web' | 'mac' | 'windows' => {
      switch (this.electron?.platform) {
        case 'darwin':
          return 'mac'
        case 'win32':
          return 'windows'
        default:
          return 'web'
      }
    })()
    return current === platform
  }

  setThemeKey(themeKey: StorageThemeKey) {
    this.themeKey = themeKey
  }

  setTypographyThemeKey(typographyThemeKey: TypographyThemeKey) {
    this.typographyThemeKey = typographyThemeKey
  }

  showEmojiPicker = (props: Omit<EmojiPickerProps, 'children'>) => {
    this.emojiPicker = { open: true, ...props }
  }

  hideEmojiPicker = () => {
    this.emojiPicker = { open: false, targetRef: null }
  }

  openMediaViewer = (media: MessageMediaModel[], index: number) => {
    this.mediaViewer = { media, index }
  }

  closeMediaViewer = () => {
    this.mediaViewer = null
  }

  reset = async () => {
    this.prompt.tearDown()
    this.emojiPicker = { open: false, targetRef: null }

    if (this.update.serviceWorker) {
      await this.update.serviceWorker.unregister()
    }

    await this.service.reset()
  }

  signOut = async (envOverride?: Env) => {
    Sentry.captureMessage('Logging out the user. Initiated by the user itself', 'debug')

    this.tearDown()

    if (this.update.serviceWorker) {
      await this.update.serviceWorker.unregister()
    }

    if (this.service.authV2.isEnabled) {
      await this.service.universalLoginReset()
      return
    }

    await this.service.clearAllAndRestart(envOverride)
  }

  showConfetti = () => {
    this.confetti = true
  }

  resetConfetti = () => {
    this.confetti = false
  }

  focus() {
    if (this.electron) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.electron.window.show?.()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.electron.window.focus?.()
    } else {
      window.focus()
    }
  }

  incrementLoadingPercentage() {
    this.loadingPercentage += this.loadingPercentageStep
  }

  *initialize() {
    try {
      // refresh token if expired
      // https://linear.app/openphone/issue/WRK-530#comment-2b2e7f0c
      if (!this.service.authV2.isEnabled && this.service.auth.isTokenExpired()) {
        yield this.service.auth.refreshToken()
      }

      this.loadingStatus = 'loading'

      yield this.loadEssentials()

      this.loadingStatus = 'success'
      this.postInitialize()
    } catch (error) {
      logError(error)
      this.loadingStatus = 'failed'
      if (error instanceof ForbiddenError) {
        this.signOut().catch(logError)
      }
    }
  }

  postInitialize() {
    this.loadNotSoEssentials()

    const user = this.service.user.getCurrentUser()
    const organization = this.service.organization.getCurrentOrganization()
    const subscription = this.service.billing.getCurrentSubscription()
    const member = user.asMember
    if (!member) {
      throw new Error('Member for current user could not be loaded')
    }

    if (
      member.email?.endsWith('openphone.co') ||
      member.email?.endsWith('openphone.com') ||
      this.service.auth.isImpersonating
    ) {
      window.app = this
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.identify({
      user,
      member,
      organization,
      subscription,
      themeKey: this.themeKey,
      workspaceSize: this.service.member.collection.length,
    })

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.transport.websocket.connect()
  }

  private *loadEssentials() {
    const promises: Promise<unknown>[] = []

    for (const essential of this.essentials) {
      promises.push(essential().then(() => this.incrementLoadingPercentage()))
    }

    yield Promise.all(promises)
  }

  loadNotSoEssentials() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.contact.loadCsvImportsV2()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.workspace.fetchUserGroups()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.blocklist.fetch()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.integration.fetchAll()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.portRequest.fetch()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.contact.fetchSettings()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.ringOrder.fetchMaxDialAtOnce()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.phoneNumber.getBlockedCountryCodes()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.contactSuggestion.fetchPending()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.member.fetchAdmin()
  }

  /**
   * Like loadEssentials, but for services that depend on a flag being enabled.
   *
   * Should get called after the user has been properly identified.
   */
  private *loadFlagDependentEssentials() {
    const promises: Promise<unknown>[] = []

    for (const flagDependentEssential of this.flagDependentEssentials) {
      promises.push(
        flagDependentEssential().then(() => this.incrementLoadingPercentage()),
      )
    }

    yield Promise.all(promises)
  }

  private async identify({
    user,
    member,
    organization,
    subscription,
    themeKey,
    workspaceSize,
  }: IdentifyData) {
    // Set user for Sentry
    SentryManager.setUser(member, {
      id: organization.id,
      plan: subscription.type ?? undefined,
    })

    // Identify the user in the Feature Flags service
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.flags.identify(user, organization, subscription, workspaceSize)

    // Identify the user in the survey service
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.survey.identify(user)

    // Identify the user in the analytics
    try {
      await this.service.analytics.identify({
        user,
        member,
        organization,
        subscription,
        themeKey,
        workspaceSize,
      })
    } catch (error) {
      logError(error)
    }
  }

  private setAsDefaultTelProtocol() {
    const hasBeenSetAlready = localStorage.getItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL)
    if (this.electron && !hasBeenSetAlready) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.electron.app.registerTelProtocol?.()
      localStorage.setItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL, '1')
    }
  }

  private async loadUiStores() {
    const {
      AlertsUiStore,
      BillingUiStore,
      CallActivityUiStore,
      ContactsUiStore,
      ConversationUiStore,
      HelpAndSupportUiStore,
      InboxesUiStore,
      SearchUiStore,
      SideMenuUiStore,
      PhoneNumberUiStore,
      ScheduledMessagesUiStore,
      AnalyticsUiStore,
      WorkspaceUiStore,
      CnamUiStore,
      PortRequestUiStore,
      TollFreeRegistrationUiStore,
      TrustRegistrationUiStore,
      VoiceUiStore,
      CallsViewUiStore,
      AiCallAssistantUiStore,
    } = await loadUiStores()

    runInAction(() => {
      this._alerts = new AlertsUiStore(this)
      this._billing = new BillingUiStore(this)
      this._contacts = new ContactsUiStore(this)
      this._inboxes = new InboxesUiStore(this)
      this._search = new SearchUiStore(this)
      this._sideMenu = new SideMenuUiStore(this)
      this._phoneNumber = new PhoneNumberUiStore(this)
      this._callActivity = new CallActivityUiStore(
        this.service.flags,
        this.service.user,
        this.service.member,
        this._phoneNumber,
      )
      this._conversation = new ConversationUiStore(this, this._callActivity)
      this._scheduledMessages = new ScheduledMessagesUiStore(this)
      this._analytics = new AnalyticsUiStore(this)
      this._workspace = new WorkspaceUiStore(this)
      this._cnam = new CnamUiStore(this)
      this._portRequest = new PortRequestUiStore(this)
      this._tollFreeRegistration = new TollFreeRegistrationUiStore(this)
      this._trustRegistration = new TrustRegistrationUiStore(this)
      this._helpAndSupport = this.service.flags.flags.webAdaChatBot
        ? new HelpAndSupportUiStore(this)
        : null
      this._voice = new VoiceUiStore(this)
      this._voice.init()
      this._callsViewCache = new CallsViewUiStore(
        this.service.activity,
        this.service.transport,
        {
          itemsPerPage: 50,
        },
      )
      this._aiCallAssistant = new AiCallAssistantUiStore(
        this.service.ai,
        this.service.transport,
      )
      this.storesLoaded = true
      this.url.checkHandlerUrl()
    })
  }

  private tearDownUiStores() {
    this._callActivity?.tearDown()
    this._callActivity = null
    this._alerts?.tearDown()
    this._alerts = null
    this._billing?.tearDown()
    this._billing = null
    this._contacts?.tearDown()
    this._contacts = null
    this._conversation?.tearDown()
    this._conversation = null
    this._inboxes?.tearDown()
    this._inboxes = null
    this._search?.tearDown()
    this._search = null
    this._sideMenu?.tearDown()
    this._sideMenu = null
    this._phoneNumber?.tearDown()
    this._phoneNumber = null
    this._scheduledMessages?.tearDown()
    this._scheduledMessages = null
    this._analytics?.tearDown()
    this._analytics = null
    this._cnam?.tearDown()
    this._cnam = null
    this._portRequest?.tearDown()
    this._portRequest = null
    this._tollFreeRegistration?.tearDown()
    this._tollFreeRegistration = null
    this._trustRegistration?.tearDown()
    this._trustRegistration = null
    this._workspace?.tearDown()
    this._workspace = null
    this._helpAndSupport?.tearDown()
    this._helpAndSupport = null
    this.storesLoaded = false
  }

  tearDown = () => {
    this.prompt.tearDown()
    this.emojiPicker = { open: false, targetRef: null }
    document.removeEventListener('scroll', this.onDocumentScroll, true)
    this.service.analytics.tearDown()
    this._helpAndSupport?.tearDown()
  }
}

/**
 * The max number of history items to keep in local storage.
 *
 * This is larger than the number of items we show in the UI to add buffer
 * in case some the stored locations result in invalid or deleted data.
 */
const MAX_STORAGE_HISTORY_ITEMS = MAX_VISIBLE_HISTORY_ITEMS + 12

class HistoryManager {
  location: Location
  locationHistory: Location[] = []
  historyNavigate: SafeAbsoluteNavigateFunction

  constructor(
    readonly router: Router,
    storage: StorageService,
    readonly flags: IFlagsService,
    readonly isStandaloneApp: boolean,
  ) {
    this.location = router.state.location
    this.historyNavigate = createSafeAbsoluteNavigate(router)

    makeAutoObservable(this, {})

    makePersistable(this, 'HistoryManager', {
      locationHistory: storage.async(),
    })

    router.subscribe(
      action((state) => {
        this.updateLocationHistory(state)
        this.location = state.location
      }),
    )
  }

  private updateLocationHistory(state: Router['state']) {
    const isRecentHistoryEnabled =
      this.flags.getFlag('webRecentHistory') && this.isStandaloneApp

    if (!isRecentHistoryEnabled) {
      return
    }

    // Strip the search, hash, and state for now to keep the list simple
    // and not have to worry about displaying additional information in
    // the UI regarding it.
    const newLocation = {
      ...state.location,
      search: '',
      hash: '',
      state: null,
    }

    // Only handle PUSH and REPLACE actions and not POP. The focus is getting
    // items into the list, not trying to be smart about removing them.
    if (
      state.historyAction === NavigationType.Push ||
      state.historyAction === NavigationType.Replace
    ) {
      // Remove potential duplicates before adding the new location to the end.
      //
      // This only needs to compare pathnames since we're stripping the search,
      // hash, and state (see the comment two above). If we decide to support
      // locations with different searches, hashes, or states, we'll need to
      // update this to use our `router/isSameLocation` util.
      const filteredLocationHistory = this.locationHistory.filter(
        (location) => location.pathname !== newLocation.pathname,
      )

      // Limit the number of items we keep in local storage to not take up
      // more space than needed.
      this.locationHistory = [...filteredLocationHistory, newLocation].slice(
        -MAX_STORAGE_HISTORY_ITEMS,
      )
    }
  }

  get pathComponents() {
    return this.location.pathname.split('/').slice(1)
  }

  /**
   * Returns everything after the ? in the url as a hash, so ?a=1&b2
   * turns into { a: 1, b: 2 }
   */
  get query(): LocationSearch {
    return fromQueryString(this.location.search) as LocationSearch
  }

  push = (path: string, search?: URLSearchParams | string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.router.navigate({
      pathname: path,
      search: stripUnknownParams(search ?? this.location.search).toString(),
    })
  }

  replace = (path: string, search?: URLSearchParams | string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.router.navigate(
      {
        pathname: path,
        search: stripUnknownParams(search ?? this.location.search).toString(),
      },
      { replace: true },
    )
  }

  consumeQueryParam = (param: LocationSearchParam): string | undefined => {
    const value = this.query[param]

    if (value) {
      const search = stripSearchParam(this.location.search, param)

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.router.navigate(
        {
          ...this.location,
          search: search.toString(),
        },
        { replace: true },
      )
    }

    return value
  }

  goBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.router.navigate(-1)
  }
}
